import React, { useState, useEffect } from "react";
import { Button, Link, Typography, Box } from "@mui/material";
import VHSButton from "./VHSButton";

const HeroCarousel = ({ currentImageIndex, sections, scrollToTop }) => {
  const [opacities, setOpacities] = useState({
    "arrow-down": 1,
    tag: 1,
    help: 1,
  });

  const handleScroll = () => {
    const newOpacities = {
      "arrow-down": Math.max(0, 1 - window.scrollY / 500),
      tag: Math.max(0, 1 - (window.scrollY - 500) / 500),
      help: Math.max(0, 1 - (window.scrollY - 1000) / 500),
    };
    setOpacities(newOpacities);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Box
        sx={{
          paddingTop: "30px",
          paddingBottom: "42px",
          width: "auto",
          height: "auto",
          backgroundColor: "transparent",
          position: "relative",
          overflow: "hidden",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "white",
            fontWeight: "bold",
            fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
            position: "relative",
            zIndex: 2,
            padding: "15vh 0 0 0",
            textAlign: "left",
            textTransform: "uppercase",
            maxLines: 1,
          }}
        >
          We create {sections[currentImageIndex].text}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "white",
            position: "relative",
            zIndex: 2,
            textAlign: "left",
          }}
        >
          {sections[currentImageIndex].subheader}
        </Typography>
        <Link href="/contact" underline="none">
          <VHSButton>
            GET A QUOTE
          </VHSButton>
        </Link>

        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            width: "100%",
            paddingTop:"60%"
          }}
        >
          <Typography
            id="tag"
            variant="h5"
            sx={{
              color: "white",
              textAlign: "left",
              letterSpacing: 3,
              opacity: opacities["tag"],
              transition: "opacity 0.3s",
              fontSize: { xs: "1rem", md: "1.5rem" },
              overflow: "hidden",
            }}
          >
            We Are Dedicated To Transforming Businesses With Every Technology
            Available To Us
          </Typography>

          <Typography
            id="help"
            sx={{
              color: "white",
              textAlign: "left",
              fontWeight: 700,
              letterSpacing: 3,
              marginTop: {xs:33,sm:25},
              opacity: opacities["help"],
              transition: "opacity 0.3s",
              fontSize: { xs: "1.5rem", md: "2rem" },
              overflow: "hidden",
            }}
          >
            LET US HELP
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "auto",
          position: "absolute",
          zIndex: 0,
          top: "33%",
          left: {xs:"15%", sm:"33%"},
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: `${sections.length * 100}%`,
            transform: `translateX(-${currentImageIndex * (100 / sections.length)}%)`,
            objectFit: "contain",
            transition: "transform 1s ease-in-out",
          }}
        >
          {sections.map((section, index) => (
            <img
              key={index}
              src={section.image}
              alt="A Device running Kratesoft software."
              style={{
                width: `${100 / sections.length}%`,
                height: "100%",
                objectFit: "contain",
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default HeroCarousel;
