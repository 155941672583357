import * as React from 'react';
import { Button, styled, keyframes } from '@mui/material';

const tracking = keyframes`
    0% {
        clip-path: inset(50% 0 50% 0);
        transform: translate(-20px, -10px);
    }
    10% {
        clip-path: inset(10% 0 85% 0);
        transform: translate(10px, 10px);
    }
    20% {
        clip-path: inset(80% 0 0 0);
        transform: translate(-10px, 10px);
    }
    30% {
        clip-path: inset(10% 0 85% 0);
        transform: translate(0px, 5px);
    }
    40% {
        clip-path: inset(50% 0 30% 0);
        transform: translate(-5px, 0px);
    }
    50% {
        clip-path: inset(10% 0 85% 0);
        transform: translate(5px, 0px);
    }
    60% {
        clip-path: inset(40% 0 43% 0);
        transform: translate(5px, 10px);
    }
    70% {
        clip-path: inset(50% 0 30% 0);
        transform: translate(-10px, 10px);
    }
    80% {
        clip-path: inset(80% 0 5% 0);
        transform: translate(20px, -10px);
    }
    90% {
        clip-path: inset(80% 0 0 0);
        transform: translate(-10px, 0px);
    }
    100% {
        clip-path: inset(80% 0 0 0);
        transform: translate(0);
    }
`;
const subtleTextEffect = keyframes`
    0%, 100% {
        text-shadow: none;
        filter: blur(0px);
    }
    90% {
        text-shadow: 0 0 3px rgba(255, 255, 255, 0.2);
        filter: blur(0.5px);
    }
`;

const hoverTextEffect = keyframes`
    0%, 100% {
        text-shadow: none;
        filter: blur(0px);
    }
    50% {
        text-shadow: 0 -1px 0 rgba(255, 0, 0, 0.7),
                                 0 1px 0 rgba(0, 255, 0, 0.7),
                                 -1px 0 0 rgba(0, 0, 255, 0.7),
                                 1px 0 0 rgba(0, 0, 255, 0.7);
        filter: blur(1px);
    }
`;

const StyledButton = styled(Button)({
    width: 200,
    height: 50,
    lineHeight: '50px',
    fontSize: '20px',
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: '#fff',
    backgroundColor: '#333',
    border: 'none',
    borderRadius: 0,
    position: 'relative',
    overflow: 'hidden',
    outline: 'none',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.2)',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
    },
    '&:hover::before': {
        opacity: .6,
        animation: `${tracking} 2s infinite`,
    },
    '&:active::before': {
        animation: `${tracking} 0.5s infinite`,
        opacity: 0.7,
    },
    '& .button-text': {
        display: 'block',
        animation: `${subtleTextEffect} 5s infinite`,
    },
    '&:hover .button-text': {
        animation: `${hoverTextEffect} 2s infinite`,
    },
    '&:active .button-text': {
        animation: `${hoverTextEffect} 0.5s infinite`,
    }
});

export default function VHSButton(props) {
    return (
        <StyledButton {...props} disableRipple>
            <span className="button-text">{props.children}</span>
        </StyledButton>
    );
}