import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'OUR FOUNDATION',
    subheader: "Rooted in Service and Leadership",
    content: (
      <div>
        <p>
          As a veteran-owned business, our foundation is built on the principles of service, 
          leadership, and dedication. We bring these values to every aspect of our operations and client relationships.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'COMMITMENT TO EXCELLENCE',
    subheader: "Upholding High Standards",
    content: (
      <div>
        <p>
          Our commitment to excellence is reflected in our work ethic and the solutions we provide. 
          Leveraging the discipline and focus honed from our military experience, we strive to exceed expectations in all our projects.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'COMMUNITY AND SUPPORT',
    subheader: "Fostering a Supportive Network",
    content: (
      <div>
        <p>
          We actively support and engage with the veteran community, fostering a network that 
          empowers veteran entrepreneurs and professionals in the tech industry.
        </p>
      </div>
    ),
    image: ContentManagement,
  }
];

const VeteranOwnedPage = () => {
  return (
    <PageLayout pageTitle="Veteran Owned Business" sections={sections}>
      {/* Additional content like testimonials, veteran initiatives, etc. */}
    </PageLayout>
  );
};

export default VeteranOwnedPage;
