import React from 'react';
import ai from '../assets/images/ai.png';
import AppCreate from '../assets/images/app-create.png';
import desktop from '../assets/images/desktop-light.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'WHAT IS ARTIFICIAL INTELLIGENCE?',
    subheader: "Understanding the basics of AI.",
    content: (
      <div>
        <p>
          Artificial Intelligence (AI) involves creating computer systems that can perform tasks 
          normally requiring human intelligence. This includes learning, problem-solving, 
          and decision making. AI is transforming how businesses operate, offering new ways to 
          automate, analyze, and enhance operations.
        </p>
      </div>
    ),
    image: ai,
  },
  {
    title: 'LEVERAGING AI IN SOFTWARE',
    subheader: "The power of AI in enhancing software solutions.",
    content: (
      <div>
        <p>
          Software companies are increasingly integrating AI to provide smarter solutions. 
          From enhancing user experience with personalized recommendations to automating routine tasks, 
          AI opens up a myriad of possibilities. It helps in analyzing large sets of data, 
          predicting trends, and making informed decisions, thereby elevating software capabilities.
        </p>
      </div>
    ),
    image: AppCreate,
  },
  {
    title: 'OUR AI SOLUTIONS',
    subheader: "Innovate and excel with our AI expertise.",
    content: (
      <div>
        <p>
          Our team is at the forefront of AI development, ready to transform your software products 
          with intelligent features. Whether you're looking to automate processes, gather insights from data, 
          or create dynamic user experiences, we have the expertise and technology to make it happen. 
          Get in touch with us to explore how AI can revolutionize your business.
        </p>
      </div>
    ),
    image: desktop,
  },
];

const AIDevelopmentPage = () => {
  return (
    <PageLayout pageTitle="Artificial Intelligence" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default AIDevelopmentPage;
