import React from 'react';
import PageLayout from '../components/PageLayout';
import SmartphoneUX from '../assets/images/smartphone-ux.png';
import DesktopUI from '../assets/images/desktop-UI.png';
import TechBoard from '../assets/images/tech-board.png';
import DesktopApplication from '../assets/images/desktop-application.png';
import OfficePainterly from '../assets/images/office-painterly.png';
import PhoneTilt from '../assets/images/phone-tilt.png';
import { Phone } from '@mui/icons-material';

const sections = [
  {
    title: 'Advanced Digital Solutions',
    subheader: 'Exceptional User Experience',
    content: (
      <div>
        <p>We begin every project by listening to your hopes and challenges, helping us discover the most effective solutions.</p>
        <p>Our approach goes beyond app or website design; it's about creating a memorable customer experience at every encounter. With every design and line of code, your and your customer's needs are our priority.</p>
      </div>
    ),
    image: SmartphoneUX,
  },
  {
    title: 'Cutting-Edge Technologies',
    subheader: 'Creating Value with User-Centric Products',
    content: (
      <div>
        <p>Using the latest technologies and design principles, we focus on speed and scalability to create products that truly resonate with users.</p>
        <p>Whether it's starting from an idea or a challenge, we build custom solutions that perfectly align with your brand's needs.</p>
      </div>
    ),
    image: DesktopUI, // Repurposed image
  },
  {
    title: 'High-Level Expertise',
    subheader: 'From Fortune 500 to Your Business',
    content: (
      <div>
        <p>Our team's experience spans working with Fortune 500 companies, now focused on providing solutions for businesses of all sizes.</p>
        <p>Whether it's streamlining operations or gaining a competitive edge, our development across iOS, Android, Web, and desktop platforms ensures comprehensive support.</p>
      </div>
    ),
    image: TechBoard, // Repurposed image
  },
  {
    title: 'Accelerating Your React Products',
    subheader: 'Maximizing Potential with React',
    content: (
      <div>
       <p>Our team is acknowledged for its expertise in developing applications using JavaScript, with a strong background in frameworks and technologies including React, Node, and Angular. This diverse experience allows us to offer comprehensive solutions in web development.</p>
       <p>Specializing in collaboration with leading tech platforms like Next.js, Reactjs, Node, and Angular, we aim to significantly elevate the caliber of projects through our in-depth knowledge and expertise.</p></div>
    ),
    image: DesktopApplication
  },
  {
    title: 'How We Can Help',
    subheader: 'Deep Expertise in React Development',
    content: (
      <div>
        <p>With some of the deepest React expertise on the planet, we have been at the forefront of building apps with it since its inception.</p>
        <p>Our comprehensive approach includes validating ideas, designing and developing world-class products, and providing expert audits and coaching to accelerate team capabilities.</p>
      </div>
    ),
    image: OfficePainterly
  },
  {
    title: 'Building New React Web or Mobile Apps?',
    subheader: 'Strategy, Design, and Development Excellence',
    content: (
      <div>
        <p>Our strategy and design teams are adept at validating your ideas, ensuring you're building the right product.</p>
        <p>We offer complete product teams that can plan, design, and develop top-tier products which gain traction and evolve in the market.</p>
        <p>Additionally, our expert React specialists are available to audit, coach, and enhance your team's capabilities.</p>
      </div>
    ),
    image: PhoneTilt,
  }
];


const AppsPage = () => {
  return (
    <PageLayout pageTitle="HOW WE BUILD APPS" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default AppsPage;