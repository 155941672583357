import React from "react";
import { Container, Typography, Link, Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ height: "35vh", bgcolor: "black", color: "white", p: 2 }}
    >
      <Container>
        <Typography
          variant="h4"
          sx={{
            my: 3,
            fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2.125rem" }, // Adjust the font size based on screen size
          }}
        >
          © 2023 Kratesoft LLC.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "start",
            mx: -3,
            px: 3,
            width: "auto",
          }}
        >
          {/* Company Links */}
          <Box sx={{ m: 3 }}>
            <Box>
              <Link
                href="/contact"
                underline="none"
                sx={{ color: "white", mb: 1.5, display: "block" }}
              >
                <Typography>Contact</Typography>
              </Link>
              <Link
                href="/apps"
                underline="none"
                sx={{ color: "white", mb: 1.5, display: "block" }}
              >
                <Typography>Apps</Typography>
              </Link>
              <Link
                href="/solutions"
                underline="none"
                sx={{ color: "white", mb: 1.5, display: "block" }}
              >
                <Typography>Solutions</Typography>
              </Link>
              <Link
                href="/about"
                underline="none"
                sx={{ color: "white", mb: 1.5, display: "block" }}
              >
                <Typography>About</Typography>
              </Link>
            </Box>
          </Box>

          {/* Product Links */}
          <Box sx={{ m: 3 }}>
            <Link
              href="/integrations"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Integrations</Typography>
            </Link>
            <Link
              href="/software"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Custom Software</Typography>
            </Link>
            <Link
              href="/artificial-intelligence"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Artificial Intelligence</Typography>
            </Link>
            <Link
              href="/mobile"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Mobile Apps</Typography>
            </Link>
          </Box>

          {/* Other Links */}
          <Box sx={{ m: 3 }}>
            <Link
              href="/opportunities"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Career Opportunities</Typography>
            </Link>
            <Link
              href="/community"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Our Community</Typography>
            </Link>
            <Link
              href="/news"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Latest News & Updates</Typography>
            </Link>
            <Link
              href="/veteran-owned"
              underline="none"
              sx={{ color: "white", mb: 1.5, display: "block" }}
            >
              <Typography>Veteran-Owned</Typography>
            </Link>
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 2,
            color: 'white',
            fontSize: '0.75rem',
            textAlign: 'center'
          }}
        >
          For more information, review our 
          <Link href="/privacy" underline="none" sx={{ color: 'white', mx: 0.5 }}>
            Privacy Policy
          </Link>
          and
          <Link href="/terms" underline="none" sx={{ color: 'white', mx: 0.5 }}>
            Terms & Conditions
          </Link>
          .
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
