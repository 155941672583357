import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'JOIN OUR TEAM',
    subheader: "Explore exciting career opportunities with us.",
    content: (
      <div>
        <p>
          Are you passionate about technology and innovation? Join a team where you can make a real impact. 
          Explore our current job openings and find a role that fits your skills and ambitions.
        </p>
        {/* Here you can link to the job listings or a portal for applications */}
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'OUR CULTURE',
    subheader: "A workplace that fosters growth and collaboration.",
    content: (
      <div>
        <p>
          We believe in a culture that fosters creativity, collaboration, and continuous learning. 
          At our company, you'll be part of an inclusive and dynamic team, where new ideas are always welcome.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'EMPLOYEE BENEFITS',
    subheader: "We care about our team's well-being.",
    content: (
      <div>
        <p>
          Our employees enjoy a range of benefits, including competitive salaries, health insurance, 
          flexible work arrangements, and continuous professional development opportunities.
        </p>
      </div>
    ),
    image: ContentManagement,
  },
  {
    title: 'GROW WITH US',
    subheader: "Build your career on a foundation of innovation.",
    content: (
      <div>
        <p>
          We support our employees in their career journey. From mentoring programs to training sessions, 
          we provide resources to help you grow professionally and achieve your career goals.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
];

const OpportunitiesPage = () => {
  return (
    <PageLayout pageTitle="Career Opportunities" sections={sections}>
      {/* Additional content or features such as job listings, application forms, etc. */}
    </PageLayout>
  );
};

export default OpportunitiesPage;
