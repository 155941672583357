import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'TAILOR-MADE SOFTWARE SOLUTIONS',
    subheader: "Crafting software that fits your unique needs.",
    content: (
      <div>
        <p>
          We specialize in developing custom software solutions that are meticulously tailored to your business requirements. 
          Whether you need a bespoke application or a specialized tool, our team delivers solutions that align perfectly with your business goals.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'AGILE AND ADAPTIVE DEVELOPMENT',
    subheader: "Responsive to changes, committed to quality.",
    content: (
      <div>
        <p>
          Our development process is agile and adaptive, ensuring that we can respond to changes and feedback quickly. 
          This approach guarantees that the final product is not only high-quality but also evolves with your business needs.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'LONG-TERM SUPPORT AND MAINTENANCE',
    subheader: "Our commitment goes beyond deployment.",
    content: (
      <div>
        <p>
          We believe in forging lasting partnerships with our clients. This means our support extends beyond just delivering 
          the software. We provide ongoing maintenance and updates to ensure your custom software continues to serve your business effectively.
        </p>
      </div>
    ),
    image: ContentManagement,
  },
];

const CustomSoftwarePage = () => {
  return (
    <PageLayout pageTitle="Custom Software Development" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default CustomSoftwarePage;
