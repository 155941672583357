import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'COLLABORATION ON GITHUB',
    subheader: "Contributing to Open Source and Community Projects",
    content: (
      <div>
        <p>
          We actively contribute to open source projects and engage with the developer community on GitHub. 
          This involvement enhances our expertise and keeps us abreast of evolving technologies.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'PARTNERSHIP WITH AI INC.',
    subheader: "Incorporating Advanced AI Technologies",
    content: (
      <div>
        <p>
          Our partnership with AI Inc. facilitates the integration of advanced artificial intelligence 
          technologies into our solutions, ensuring innovative and efficient outcomes for our clients.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'ALLIANCE WITH PIMIOS',
    subheader: "Elevating Data Management and Analytics",
    content: (
      <div>
        <p>
          Our alliance with Pimios enhances our capabilities in data management and analytics, 
          ensuring robust performance and scalability for all projects.
        </p>
      </div>
    ),
    image: ContentManagement,
  },
  {
    title: 'EXTENSIVE TECHNOLOGY NETWORK',
    subheader: "Expertise for Projects of Any Scale",
    content: (
      <div>
        <p>
          Our extensive network with premier technology manufacturers and top-tier software/design agencies 
          enables us to efficiently manage and execute projects of any scale and complexity.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
];

const CommunityPage = () => {
  return (
    <PageLayout pageTitle="Our Community Engagement" sections={sections}>
      {/* Additional content, testimonials, case studies, etc. */}
    </PageLayout>
  );
};

export default CommunityPage;
