import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'LATEST DEVELOPMENTS',
    subheader: "Staying Ahead in Technology",
    content: (
      <div>
        <p>
          Stay informed about our latest developments and innovations in technology. 
          Discover how we are pushing boundaries and setting new standards in the industry.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'COMPANY ANNOUNCEMENTS',
    subheader: "Updates and Milestones",
    content: (
      <div>
        <p>
          Keep up-to-date with our company announcements. Learn about our milestones, 
          new partnerships, and strategies as we continue to grow and evolve.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'INDUSTRY INSIGHTS',
    subheader: "Thought Leadership and Perspectives",
    content: (
      <div>
        <p>
          Gain insights from our thought leaders on the trends shaping our industry. 
          Our articles and analyses provide valuable perspectives on emerging technologies and market dynamics.
        </p>
      </div>
    ),
    image: ContentManagement,
  },
  {
    title: 'EVENTS AND ENGAGEMENTS',
    subheader: "Connect with Us",
    content: (
      <div>
        <p>
          Learn about upcoming events, webinars, and conferences where you can connect 
          with our team, learn about our solutions, and engage with industry experts.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
];

const NewsPage = () => {
  return (
    <PageLayout pageTitle="Latest News and Insights" sections={sections}>
      {/* Additional content or interactive elements like a news feed, subscription form, etc. */}
    </PageLayout>
  );
};

export default NewsPage;
