import React from 'react';
import PageLayout from '../components/PageLayout';
import DesktopUI from '../assets/images/desktop-UI.png';
import ArcReactor from '../assets/images/arc-reactor.png';
import ServerHosting from '../assets/images/server-hosting.png';
import AppUsers from '../assets/images/app-users.png';
import Application from '../assets/images/desktop-application.png';

const sections = [
  {
    title: 'Digital Experience',
    subheader: 'Crafting World-Class Digital Solutions',
    content: (
      <div>
        <p>KrateSoft specializes in creating immersive and impactful digital experiences, combining human-centered design with innovative technology.</p>
        <p>We focus on delivering solutions that resonate with users, enhancing how they interact with digital platforms.</p>
      </div>
    ),
    image: DesktopUI, // Repurposed image focused on digital solutions
  },
  {
    title: 'Fast Transformation',
    subheader: 'Rapid Development and Deployment',
    content: (
      <div>
        <p>Embracing a philosophy of rapid transformation, KrateSoft ensures swift development and deployment of digital products.</p>
        <p>We employ efficient methodologies to bring your ideas to life quickly without compromising on quality.</p>
      </div>
    ),
    image: ArcReactor, // Repurposed image illustrating speed and efficiency
  },
  {
    title: 'Agile Approach',
    subheader: 'Flexible and Responsive Development',
    content: (
      <div>
        <p>Our agile approach allows for adaptive planning, evolutionary development, early delivery, and continuous improvement.</p>
        <p>We respond to changes swiftly, ensuring that our solutions remain relevant and effective in a dynamic digital landscape.</p>
      </div>
    ),
    image: ServerHosting, // Repurposed image that represents agility and adaptability
  },
  {
    title: 'Expertise in JavaScript',
    subheader: 'Advanced JavaScript Solutions',
    content: (
      <div>
        <p>With deep expertise in JavaScript, KrateSoft excels in developing advanced web and mobile applications.</p>
        <p>Our team is proficient in React and other leading JavaScript frameworks, crafting robust and scalable solutions.</p>
      </div>
    ),
    image: AppUsers, // Repurposed image related to JavaScript technologies
  },
  {
    title: 'Expertise in UX',
    subheader: 'User-Centric Design and Development',
    content: (
      <div>
        <p>Our expertise in UX design ensures that every product we create offers an exceptional and intuitive user experience.</p>
        <p>We focus on understanding user needs and behaviors to design interfaces that are both beautiful and functional.</p>
      </div>
    ),
    image: Application, // Repurposed image showcasing UX design work
  },
];


const AboutPage = () => {
  return (
    <PageLayout pageTitle="ABOUT US" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};



export default AboutPage;