import React, { useState } from 'react';
import { Box, TextField, Grid, Button, Snackbar, Alert, Link } from '@mui/material';
import PageLayout from '../components/PageLayout';
import OfficePainterly from '../assets/images/office-painterly.png';
import ArcReactor from '../assets/images/arc-reactor.png';
import ServerHosting from '../assets/images/server-hosting.png';
import AboutUs from '../assets/images/about-us.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import VHSButton from '../components/VHSButton';

const firebaseConfig = {
  apiKey: "AIzaSyAaZRavRh5I51BNgRRkLcWqspF1ySdH4sQ",
  authDomain: "kratesoft-com.firebaseapp.com",
  projectId: "kratesoft-com",
  storageBucket: "kratesoft-com.appspot.com",
  messagingSenderId: "994015306446",
  appId: "1:994015306446:web:69faaccc2316015936a69f",
  measurementId: "G-TYSVWS0Y23"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const ContactPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !message || !recaptchaValue) {
      setSnackbarMessage("Please fill all fields and complete the reCAPTCHA.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await addDoc(collection(firestore, "contacts"), {
        firstName,
        lastName,
        email,
        message
      });
      setSnackbarMessage("Message sent successfully!");
      setSnackbarSeverity('success');
      setFormSubmitted(true);
      setSnackbarOpen(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      setRecaptchaValue('');
    } catch (error) {
      setSnackbarMessage("Error: " + error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  
  const sections = [
    {
      title: "Direct Contact",
      subheader: "",
      content: (
        <div>
          <p>
            Email us at{" "}
            <Link
              href="mailto:info@kratesoft.com"
              style={{ color: "#FFFFFF" }}
            >
              info@kratesoft.com
            </Link>{" "}
            <br/>
            or call us at{" "}
            <Link href="tel:+17242648086" style={{ color: "#FFFFFF" }}>
              (724) 264-8086
            </Link>
            .
          </p>
          <p>
            For career inquiries, email{" "}
            <Link
              href="mailto:careers@kratesoft.com"
              style={{ color: "#FFFFFF" }}
            >
              careers@kratesoft.com
            </Link>
            .
          </p>
        </div>
      ),
      image: OfficePainterly, // Replace with your image URL
    },
    {
      title: "Our Location",
      subheader: "",
      content: (
        <div>
          <p>
            Situated in Western Pennsylvania, near Grove City College, Penn
            State University, and Slippery Rock University, our office is
            strategically located in an area known for its thriving tech scene.
            This region's innovation is fueled by institutions like Carnegie
            Mellon University, making it an ideal hub for our operations.
          </p>
        </div>
      ),
      image: ArcReactor, // Replace with your image URL
    },
    {
      title: "Accelerate Your Vision",
      subheader: "",
      content: (
        <div>
          <p>
            At Kratesoft, we specialize in Application Development, uniquely
            positioning us to propel businesses across diverse verticals. Our
            expertise isn't just about speed; it's about creating cohesive
            digital experiences. From engaging social media interactions and
            impactful marketing strategies to the development of captivating
            websites and intuitive mobile applications, we ensure every
            touchpoint resonates with quality and consistency.
          </p>
        </div>
      ),
      image: ServerHosting, // Replace with your image URL
    },
    {
      title: "Precision in Digital Transformation",
      subheader: "",
      content: (
        <div>
          <p>
            Leveraging cutting-edge technology and unparalleled expertise, we
            turn ambitious ideas into reality at a velocity that sets you apart.
            Whether it's transforming customer experiences, streamlining
            operations, or launching groundbreaking products, our commitment is
            unwavering: to deliver exceptional results with unmatched speed and
            precision. Connect with us and embark on a journey to redefine the
            boundaries of digital innovation.
          </p>
        </div>
      ),
      image: AboutUs, // Replace with your image URL
    },
    // Add more sections as needed
  ];


  const contactForm = formSubmitted ? null : (
    <form onSubmit={handleSubmit}>
      <Box padding="25px 0">
        <Grid container spacing={2} style={{ marginBottom: "40px" }}>
          {/* First Name Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#333333",
                  color: "#FFFFFF",
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* Last Name Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#333333",
                  color: "#FFFFFF",
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* Email Input */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#333333",
                  color: "#FFFFFF",
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* Message Input */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="How can we help?"
              variant="outlined"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: "#333333",
                  color: "#FFFFFF",
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{ style: { color: "#FFFFFF" } }}
            />
          </Grid>
          {/* reCAPTCHA */}
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <ReCAPTCHA
              sitekey="6Lf_8QAVAAAAAGjZ2RsjiaZJBLsMJzVvuyY7YPbL"
              onChange={onRecaptchaChange}
            />
          </Grid>
          {/* Submit Button */}
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <VHSButton type="submit">
              Submit
            </VHSButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );

  return (
    <PageLayout pageTitle="Contact Us" sections={sections}>
      {contactForm}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </PageLayout>
  );
};

export default ContactPage;
