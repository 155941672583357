import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'API AND SOFTWARE INTEGRATION',
    subheader: "Seamlessly connecting your systems.",
    content: (
      <div>
        <p>
          Our expertise in API and software integration ensures your diverse applications and systems 
          work together harmoniously. Streamline your operations by allowing different software products 
          to communicate and share data efficiently.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'DATA MIGRATION SERVICES',
    subheader: "Secure and efficient data transfer.",
    content: (
      <div>
        <p>
          Migrate your critical data safely and with minimal disruption. Our team handles complex data migrations, 
          ensuring integrity and security. Transition to new platforms smoothly without losing valuable data.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'OPTIMIZING BUSINESS PROCESSES',
    subheader: "Enhance efficiency with integrated solutions.",
    content: (
      <div>
        <p>
          By integrating disparate systems and migrating data effectively, we help optimize your business processes. 
          Achieve greater efficiency, accuracy, and speed in your operations, driving your business towards sustained growth.
        </p>
      </div>
    ),
    image: ContentManagement,
  },
];

const IntegrationsPage = () => {
  return (
    <PageLayout pageTitle="Integration Solutions" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default IntegrationsPage;
