import { createTheme } from '@mui/material/styles';

const KratesoftTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: "#000000",
    },
  },
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    h1: {
      fontWeight: 700, // Extra Bold
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600, // Bold
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 500, // Medium
      fontSize: '2.5rem',
    },
    h4: {
      fontWeight: 500, // Medium
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 400, // Regular
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 400, // Regular
      fontSize: '1.25rem',
    },
    subtitle1: {
      fontWeight: 400, // Regular
      fontSize: '1rem',
    },
    subtitle2: {
      fontWeight: 300, // Light
      fontSize: '0.875rem',
    },
    body1: {
      fontWeight: 400, // Regular
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 300, // Light
      fontSize: '0.875rem',
    },
    button: {
      fontWeight: 500, // Medium
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 300, // Light
      fontSize: '0.75rem',
    },
    overline: {
      fontWeight: 500, // Medium
      fontSize: '0.625rem',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          position: "relative",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFFFFF"
          },
          "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFFFFF",
            "@media (hover: none)": {
              borderColor: "#FFFFFF"
            }
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFFFFF",
            borderWidth: 1
          }
        }
      }
    }
  },
  spacing: 8,
});

export default KratesoftTheme;
