import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png'
import DesignSystems from '../assets/images/DesignSystems.png'
import ContentManagement from '../assets/images/ContentManagement.png'
import PageLayout from '../components/PageLayout';


const sections = [
  {
    title: 'DISCOVERY SESSION',
    subheader: "We begin every project by listening to your hopes and challenges, helping us discover the most effective solutions.",
    content: <div><p>Customer experience goes beyond just app design or website design. 
                  It goes to the way your customer feels and what they think of your 
                  business from each encounter. With every design we draw and line of 
                  code we write, we have your needs and your customer's experience in mind.</p> 
              </div>,
    image: DigitalExpansion,
  },
  {
    title: 'CUTTING EDGE TECHNOLOGIES',
    subheader: "We use the latest technologies and design principles to provide the highest level of speed and scalability.",
    content: <div><p>We create valuable products with the user in mind.  We build apps 
                  from scratch to perfectly fit the needs of your brand. We can start 
                  with your idea or with a challenge and build a solution.</p> 
              </div>,
    image: DesignSystems,
  },
  {
    title: 'HIGH LEVEL EXPERTISE',
    subheader: "Our development team has previously worked with dozens of Fortune 500 companies.",
    content: <div><p>Now we work for you. KrateSoft's current clients range from small startups
                   to multi-million dollar companies. So whether you need to streamline your operations
                   or give your business an edge, we can handle it. We can develop for any platform:
                  iOS, Android, Web, or desktop.</p> 
  
              </div>,
    image: ContentManagement,
  },
];


const SolutionsPage = () => {
  return (
    <PageLayout pageTitle="ADVANCED DIGITAL SOLUTIONS" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default SolutionsPage;
