import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import AppsPage from './pages/AppsPage';
import SolutionsPage from './pages/SolutionsPage';
import KratesoftTheme from './themes/KratesoftTheme';
import AIDevelopmentPage from './pages/AIDevelopmentPage';
import IntegrationsPage from './pages/IntegrationsPage';
import CustomSoftwarePage from './pages/CustomSoftwarePage';
import MobileAppDevelopmentPage from './pages/MobileAppDevelopmentPage';
import CommunityPage from './pages/CommunityPage';
import OpportunitiesPage from './pages/OpportunitiesPage';
import VeteranOwnedPage from './pages/VeteranOwnedPage';
import NewsPage from './pages/NewsPage';
import TermsOfServicePage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';

function App() {
    useEffect(() => {
      // Reset scroll position to the top on route change
      window.scrollTo(0, 0);
    }, []);

    return (
      <ThemeProvider theme={KratesoftTheme}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/apps" element={<AppsPage />} />
            <Route path="/solutions" element={<SolutionsPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/artificial-intelligence" element={<AIDevelopmentPage />} />
            <Route path="/integrations" element={<IntegrationsPage />} />
            <Route path="/custom-software" element={<CustomSoftwarePage />} />
            <Route path="/mobile" element={<MobileAppDevelopmentPage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/opportunities" element={<OpportunitiesPage />} />
            <Route path="/veteran-owned" element={<VeteranOwnedPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="*" element={<LandingPage />} />
            <Route path="/terms" element={<TermsOfServicePage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    );
}

export default App;
