import React from 'react';
import PageLayout from '../components/PageLayout';

// Plain text for Terms of Service
const termsOfServiceContent = `TERMS OF SERVICE
These Terms of Service (the "Agreement") form a legally binding contract between you (the "User") and Krate Soft, LLC (the "Company") regarding your use of the Company's software development, agile development/delivery, website design and development, and hourly contract software work services (the "Service"). By using the Service, you agree to be bound by the terms and conditions of this Agreement. If you do not agree to these terms, you should not use the Service. Please read this Agreement carefully before using the Service.


DISCLAIMER OF WARRANTIES

The information on this website and the software/website provided by Krate Soft, LLC are provided on an “as is” basis. To the fullest extent permitted by law, Krate Soft, LLC excludes all representations and warranties relating to this website and the software/website or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the software/website. Krate Soft, LLC excludes all liability for damages arising out of or in connection with your use of our software/website. This includes, without limitation, direct loss, loss of business or profits, damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages. This excludes liability for death or personal injury caused by Krate Soft, LLC’s negligence.


DESCRIPTION OF SERVICE

Krate Soft, LLC provides software development and website design and development services. Client is responsible for obtaining access to Krate Soft, LLC’s services that may involve 3rd party fees (including but not limited to, ISP, merchant accounts and gateways). Client is also responsible for all equipment and software necessary to access Krate Soft, LLC’s services.


SCOPE OF WORK

The Client acknowledges and agrees that the scope of work for the development of the software/website is as set forth in the Client proposal and Project Specifications Document. The software/website will only include the specific features, functionality, and number of pages purchased by the Client as set forth in the Client proposal and Project Specifications Document. If the Client requests additional features, functionality, or pages beyond the scope of work purchased, the Contractor may provide a price quote for such additional work. Any additional work requested by the Client outside the scope of work purchased may be charged at an hourly rate or as specific enhancements. The Client further acknowledges and agrees that the Contractor is not responsible for any delays in the completion of the project resulting from the Client's failure to provide information or feedback necessary for the completion of the project.  If the Client desires additional features, functionality or pages client may request a price quote to purchase as additional enhancements to the software/website. Additional work requested by the Client outside of the scope of work purchased may be charged at an hourly rate or as specific enhancements.


EXPEDITED SERVICES

While Krate Soft, LLC does not guarantee a time frame for the completion of any custom website or software project, it may offer an optional expedited service to Client for a fee. Expedited service is not available for all accounts and all requests for expedited service must be approved by Krate Soft, LLC at its sole discretion. Client understands and agrees that Client’s use of expedited service does not guarantee that Client’s software/website including its corresponding design, enhancements, databases, e-commerce stores, flash, etc, will be completed more quickly than they would be without the use of the expedited service. The expedited service fee only ensures that Krate Soft, LLC will make reasonable efforts to more quickly assign designers and programmers to the account in an effort to facilitate development than would be the case under the ordinary developmental process.


COMPLETION TIMEFRAMES

Client understands, agrees and acknowledges that Krate Soft, LLC does not guarantee a time frame for completion of ANY website or software project. This is in part because it is difficult to complete a project without design and functionality approvals and participation from the Client. In addition, if the Client continues to submit additional content throughout the development process, or requests additional modifications to the project, the completion time frame is increased.  Other factors that may influence the completion date of a project include, but are not limited to, the complexity of the project(s), the availability of required information or content from the Client, changes or modifications requested by the Client, unforeseen technical issues, and the accounting status of the Client's account. It is important to note that the completion date is an estimate, and Krate Soft, LLC will make reasonable efforts to complete the project in a timely manner. However, unforeseeable circumstances may arise which could cause delays beyond Krate Soft, LLC's control, and Krate Soft, LLC shall not be held liable for any such delays.


REFUND POLICY

The fees paid for website/software development are non-refundable once work has commenced, including but not limited to, the design work and programming having been completed and/or the software has been moved to client’s server or transferred to their possession.


If the client cancels the project before work has started, a minimum of a 50% cancellation fee will be retained by the contractor to compensate for the time costs incurred for project meetings and analysis, project management, documentation, design and development, employee expenses, and overhead costs.


If work has commenced on the project and the Client decides to cancel, the Contractor reserves the right to charge additional fees to cover any expenses already incurred, including but not limited to, time costs for project meetings and analysis, project management, documentation, design and development, employee expenses, and overhead costs. The amount charged will be based on the actual expenses incurred up to the point of cancellation and will be communicated to the Client in writing. The Contractor will not be liable for any losses or damages resulting from the cancellation.


By accepting a refund in full or refund less cancellation fee, the client agrees that the matter is settled in full and releases the contractor, its officers, owners, members, agents, and employees of any and all contractual obligations and waives all claims of any nature, including legal action, against the contractor, its officers, owners, members, agents, and employees.


MONITORING OF PHONE CALLS

We may monitor and record phone calls between the User and our customer service representatives or other company representatives for quality assurance and training purposes. By using the Service, the User consents to such monitoring and recording. Any personal information disclosed during a monitored phone call will be handled in accordance with our Privacy Policy. If the User does not wish to have their phone calls monitored or recorded, they should inform the representative at the beginning of the call. Please note that if the User chooses to not allow monitoring or recording, our ability to provide customer support may be limited.


RIGHTS TO INTELLECTUAL PROPERTY

The Client warrants that it has the right to use any and all intellectual property provided to Krate Soft, LLC and agrees to indemnify and hold harmless Krate Soft, LLC from any and all claims or expenses (including reasonable attorneys’ fees) arising out of or in connection with any claim of infringement or the like based upon any information, data, design, text, photograph, graphic, audio or video material or any other material provided by the Client to Krate Soft, LLC including, but not limited to, the Client’s trademarks, service marks, trade names, logos, copyrighted material, patents, or trade secrets. The Client further warrants that any material supplied to Krate Soft, LLC for use in connection with Client’s project will be free from any claims or encumbrances, including but not limited to any liens or security interests, charges or other encumbrances in favor of any third party.


TERMINATION OF SERVICES

The Client may terminate its agreement with Krate Soft, LLC at any time by submitting written notice to Krate Soft, LLC of its intention to terminate the agreement. Upon receipt of such notice, Krate Soft, LLC will terminate the services within 30 business days of receipt. The Client will not be refunded for any fees paid for services already rendered. Krate Soft, LLC may also terminate the agreement at any time without prior notice to the Client in the event of a material breach by the Client of its obligations under the agreement, including, but not limited to, any failure to make timely payment of fees or if the Client violates any applicable law or regulation.



LATE PAYMENT POLICY

In the event that the Client fails to make any payment when due under this Agreement, the Contractor may charge interest on the outstanding amount at a rate of 5% per month or the maximum rate allowed by law, whichever is less. The Contractor may also suspend any work or services until the outstanding amount is paid in full, without liability for any losses or damages resulting from such suspension. The Contractor may also pursue legal action to recover any outstanding amounts, and the Client shall be liable for all costs and expenses incurred by the Contractor in connection with such legal action, including reasonable attorney's fees.


APPLICABLE LAW

The laws of the State of Pennsylvania shall govern the rights and obligations of the parties under this agreement, without giving effect to any principles of conflicts of laws. The Client and Krate Soft, LLC agree that any dispute arising out of or in connection with this agreement shall be brought in the courts of Pennsylvania, and the parties hereby consent to the personal jurisdiction and venue of these courts.


ENTIRE AGREEMENT

This agreement contains the entire agreement between the Client and Krate Soft, LLC and supersedes any prior understandings or agreements, whether written or oral. No modification of this agreement will be binding unless in writing and signed by both the Client and Krate Soft, LLC. The terms and conditions of this agreement will inure to the benefit of Krate Soft, LLC’s successors, assigns, and licensors. If any provision of this agreement is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.


By using Krate Soft, LLC’s services, the Client agrees to be bound by this agreement and all of its terms and conditions.



FORCE MAJEURE

Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood, or any other natural or man-made eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any party affected by such an event shall forthwith inform the other party of the same and shall use all reasonable endeavors to comply with the terms and conditions of any Agreement contained herein.


WAIVER

Failure of either party to insist upon strict performance of any provision of this or any Agreement or the failure of either party to exercise any right or remedy to which it, he or they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any Agreement. No waiver of any of the provisions of this or any Agreement shall be effective unless it is expressly stated to be such and signed by both parties.


GENERAL

The laws of the State of Pennsylvania govern these terms and conditions. By accessing this website, you consent to these terms and conditions and to the exclusive jurisdiction of the Pennsylvania County, Pennsylvania courts in all disputes arising out of such access. If any of these terms are deemed invalid or unenforceable for any reason (including, but not limited to the exclusions and limitations set out above), then the invalid or unenforceable provision will be severed from these terms, and the remaining terms will continue to apply. Failure of the company to enforce any of the provisions set out in these Terms and Conditions and any Agreement, or failure to exercise any option to terminate, shall not be construed as a waiver of such provisions and shall not affect the validity of these Terms and Conditions or of any Agreement or any part thereof, or the right thereafter to enforce each and every provision. These Terms and Conditions shall not be amended, modified, varied, or supplemented except in writing and signed by duly authorized representatives of the Company.


NOTIFICATION OF CHANGES

The Company reserves the right to change these conditions from time to time as it sees fit, and your continued use of the site will signify your acceptance of any adjustment to these terms. If there are any changes to our privacy policy, we will announce that these changes have been made on our home page and on other key pages on our site. If there are any changes in how we use our site customers’ Personally Identifiable Information, notification by e-mail or postal mail will be made to those affected by this change. Any changes to our privacy policy will be posted on our website 30 days prior to these changes taking place. You are, therefore, advised to re-read this statement on a regular basis.


CONCLUSION

These terms and conditions form part of the Agreement between the Client and ourselves. Your accessing of this website and/or undertaking of a booking or Agreement indicates your understanding, agreement to, and acceptance of the Disclaimer Notice and the full Terms and Conditions contained herein. Your statutory Consumer Rights are unaffected.

`;

const TermsOfServicePage = () => {
  const sections = [
    {
      title: 'Read Carefully these Terms of Service',
      subheader: 'In contracting our services or using our website you are deemed to have read and agreed to the following terms and conditions:',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {termsOfServiceContent}
        </div>
      ),
    },
  ];

  return (
    <PageLayout pageTitle="Terms of Service" sections={sections}>
      {/* Additional content like updates, amendments, etc., can be added here */}
    </PageLayout>
  );
};

export default TermsOfServicePage;
