import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import DigitalExpansion from "../assets/images/DigitalExpansion.png";
import DesignSystems from "../assets/images/DesignSystems.png";
import ContentManagement from "../assets/images/ContentManagement.png";
import FrontEndDev from "../assets/images/FrontEndDev.png";
import ProductCreation from "../assets/images/ProductCreation.png";
import PageLayout from "../components/PageLayout.js";
import HeroCarousel from "../components/HeroCarousel";
import phoneImage from "../assets/images/phone.png";
import laptopImage from "../assets/images/laptop-photo.png";
import monitorImage from "../assets/images/monitor-image.png";
import serverImage from "../assets/images/servers.png";

const sections = [
  {
    title: "Digital Expansion",
    subheader:
      "Enhance your ability to deliver value to customers through our Service Design proficiency and expansion approach",
    content: (
      <div>
        <Typography>
          Surmount organizational challenges and achieve consensus on critical
          objectives
        </Typography>
        <Typography>
          Translate intangible digital experiences into tangible, visual results
        </Typography>
        <Typography>
          Thrive by embracing a micro-transformation framework, and excel in
          customer experience
        </Typography>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: "Design Systems",
    subheader:
      "Ensure punctual and brand-aligned delivery with a Design System. They serve as the foundation for contemporary large-scale digital product development.",
    content: (
      <div>
        <Typography>Unleash exceptional customer experiences</Typography>
        <Typography>
          Embrace accessibility and legal compliance as integral components
        </Typography>
        <Typography>
          Pave the way for scalable design and development in the future
        </Typography>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: "Content Management Systems (CMS)",
    subheader:
      "Stay in sync with the market's pace using a Headless CMS. Empower your marketing, merchandising, and content creation teams to scale their efforts.",
    content: (
      <div>
        <Typography>Secure the future of your Martech stack</Typography>
        <Typography>
          Eliminate roadblocks for your marketing, merchandising, and content
          teams
        </Typography>
        <Typography>
          Craft personalized digital experiences that drive conversions
        </Typography>
      </div>
    ),
    image: ContentManagement,
  },
  {
    title: "Product Creation",
    subheader:
      "Prioritize gaining traction with new products, our strategy takes you from identifying a business challenge or opportunity to delivering a testable proof of concept, ensuring a successful start.",
    content: (
      <div>
        <Typography>
          Validate and progressively develop based on genuine feedback
        </Typography>
        <Typography>
          Align priorities with operational software and market insights
        </Typography>
        <Typography>
          Implement effective analytics to measure and learn along the way
        </Typography>
      </div>
    ),
    image: ProductCreation,
  },
  {
    title: "Front-End Development",
    subheader:
      "Elevate your Front-end teams for the creation of contemporary digital products or the seamless migration of your tech stack to a modern web framework.",
    content: (
      <div>
        <Typography>
          Extensive 30+ years experience crafting React, Angular, and Vue web
          and mobile applications
        </Typography>
        <Typography>
          Ensure a secure and successful transition to a modern codebase
        </Typography>
        <Typography>
          Foster skill development and leadership within your teams
        </Typography>
      </div>
    ),
    image: FrontEndDev,
  },
];

const heroSections = [
  { text: "Apps", image: phoneImage },
  { text: "Software", image: laptopImage },
  { text: "Solutions", image: monitorImage },
  { text: "Products", image: serverImage },
];


const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalDuration = 4000;

  useEffect(() => {
    const changeInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroSections.length); // Adjust to cycle through all images
    }, intervalDuration);

    return () => clearInterval(changeInterval);
  }, []);

  return (
    <PageLayout pageTitle="" sections={sections}>
      <HeroCarousel
        currentImageIndex={currentIndex}
        sections={heroSections}
        scrollToTop={scrollToTop}
      />
    </PageLayout>
  );
};

export default LandingPage;