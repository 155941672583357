import React, { useState } from 'react';
import {
  SwipeableDrawer,
  Paper,
  Backdrop,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100%',
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    borderRadius: 0,
    borderLeft: 'solid 1px #333333',
    padding: '50px 20px 20px 20px',
  },
  listItem: {
    paddingLeft: '0px',
  },
  contactInfo: {
    textAlign: 'left',
  },
  listItemText: {
    fontWeight: 'bold',
    fontSize: '20px',
    textTransform: 'uppercase',
  },
}));

const SiteDrawer = () => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const locations = [
    'Contact',
    'Apps',
    'Solutions',
    'About',
    'Artificial Intelligence',
    'Integrations',
    'Custom Software',
  ];

  return (
    <div>
      <IconButton color="inherit" onClick={toggleDrawer(true)} style={{ fontSize: '18px' }}>
        <MenuIcon />
      </IconButton>
      <Backdrop
        open={isDrawerOpen}
        onClick={toggleDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      />
      <SwipeableDrawer
        className={classes.drawer}
        variant="temporary"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        anchor="right"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <div className={classes.drawerPaper}>
          <List>
            {locations.map((location, index) => (
              <Link to={`/${location.toLowerCase().replace(/\s+/g, '-')}`} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem button className={classes.listItem}>
                  <ListItemText primary={<Typography variant="body1" className={classes.listItemText}>{location}</Typography>} />
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
          <div className={classes.contactInfo}>
            <Typography>Krate Soft, LLC</Typography>
            <Typography>519 Greenville Rd.</Typography>
            <Typography style={{ marginBottom: '50px' }}>Mercer, PA 16137</Typography>
            <Typography style={{ marginBottom: '50px' }}>(724) 264 - 8086</Typography>
            <Typography>info@kratesoft.com</Typography>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default SiteDrawer;
