import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import logo from "../assets/images/KS_Logo.png";
import Drawer from "../components/Drawer.js";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const appBarStyle = {
    boxShadow: "none",
    backgroundColor: {xs: "black", md: "transparent"},
    display: "flex",
    justifyContent: "space-between",
    padding: { xs: "0", md: "10px", lg: "20px" },
    width: { xs: "100%", md: "50%" },
    position: {xs: "fixed", md: "static"}
  };

  const logoStyle = {
    width: "18px",
    height: "auto",
  };

  return (
    <AppBar sx={appBarStyle}>
      <Toolbar>
        {/* Logo on the left */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
          }}
        >
          <Button color="inherit" component={Link} to="/">
            <img src={logo} alt="Kratesoft Logo" style={logoStyle} />
          </Button>
        </Box>

        {/* Links and dividers for desktop (sm and above) */}
        
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            borderBottom: "solid 1px white",
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Button color="inherit" component={Link} to="/">
              <img src={logo} alt="Kratesoft Logo" style={logoStyle} />
            </Button>
          </Box>
          
          <Button
            color="inherit"
            component={Link}
            to="/contact"
            sx={{ marginX: 1 }}
          >
            Contact
          </Button>
          <Typography variant="h6" component="div" sx={{ marginX: 1 }}>
            /
          </Typography>
          <Button
            color="inherit"
            component={Link}
            to="/apps"
            sx={{ marginX: 1 }}
          >
            Apps
          </Button>
          <Typography variant="h6" component="div" sx={{ marginX: 1 }}>
            /
          </Typography>
          <Button
            color="inherit"
            component={Link}
            to="/solutions"
            sx={{ marginX: 1 }}
          >
            Solutions
          </Button>
          <Typography variant="h6" component="div" sx={{ marginX: 1 }}>
            /
          </Typography>
          <Button
            color="inherit"
            component={Link}
            to="/about"
            sx={{ marginX: 1 }}
          >
            About
          </Button>
          <Typography variant="h6" component="div" sx={{ marginX: 1 }}>
            /
          </Typography>
          <Drawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          />
          {/* Add more buttons/links as needed */}
        </Box>
        {/* Drawer Component */}
        <Box sx={{ display: { xs: "flex", sm: "none" } }}>
          <Drawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
