import React from 'react';
import PageLayout from '../components/PageLayout';


const termsOfServiceContent = `
Last updated: 10/30/2023

Welcome to Kratesoft.com (the "Site"), owned and operated by Kratesoft LLC. This Privacy Policy outlines the types of information we collect from visitors to our Site, how we use that information, and the steps we take to safeguard your privacy.

COLLECTION OF INFORMATION

a. Information You Provide: We do not collect personal information from visitors unless they provide it voluntarily, such as by emailing us or subscribing to a newsletter.

b. Automatic Data Collection: When you visit Kratesoft.com, certain information is automatically collected, including your IP address, browser type and operating system, date and time of your visit, and pages you visit on our Site.

c. Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track activity on our Site and hold certain information. This includes cookies from third-party partners, such as Google, for analytics and advertising purposes.

USE OF INFORMATION

Your information is used for the following purposes:

To ensure the proper functioning and security of our Site
To improve our website and tailor it to user preferences
For web analytics to understand how our Site is used
To display personalized advertising through Google Ads
SHARING OF INFORMATION

We do not sell, trade, or rent users’ personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners for the purposes outlined above.

GOOGLE ANALYTICS AND GOOGLE ADS

Our Site uses Google Analytics to understand how visitors engage with our Site. We also use Google Ads for advertising purposes. Google’s use of advertising cookies enables it and its partners to serve ads based on your visit to our Site and/or other sites on the Internet. You may opt out of personalized advertising by visiting Google’s Ads Settings.

DATA SECURITY

We are committed to ensuring the security of your information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.

LINKS TO OTHER WEBSITES

Our Site may contain links to other websites. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.

CHANGES TO THIS PRIVACY POLICY

Kratesoft LLC reserves the right to update or change our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.

YOUR ACCEPTANCE OF THESE TERMS

By using this Site, you signify your acceptance of this policy. If you do not agree do not use this site.`;

const PrivacyPage = () => {
  const sections = [
    {
      title: 'User Data and Privacy Guidelines',
      subheader: 'Please Read Carefully',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {termsOfServiceContent}
        </div>
      ),
    },
  ];

  return (
    <PageLayout pageTitle="Privacy Policy" sections={sections}>
      {/* Additional content like updates, amendments, etc., can be added here */}
    </PageLayout>
  );
};

export default PrivacyPage;
