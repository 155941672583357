import React, { useState } from 'react';
import { Typography, Divider, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";
import logo from "../assets/images/logo-white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const PageLayout = ({ children, pageTitle, sections }) => {
  const [loaded, setLoaded] = useState(false);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: !loaded ? "black" : "transparent",
    opacity: loaded ? 1 : 0,
    transition: "opacity 1s ease, background-color 1s ease",
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "transparent",
          p: { xs: 2, md: "0 25vh" },
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar />

        <Box
          sx={{
            position: "fixed",
            left: { md: "-50px" },
            top: "50%",
            zIndex: 9999,
            transform: "rotate(-90deg)",
            width: "200px",
            display: { xs: "none", md: "block" },
          }}
        >
          <Button color="inherit" component={Link} to="/" onClick={scrollToTop}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "100%" }}
            />
          </Button>
        </Box>

        {pageTitle && (
          <Typography
            variant="h1"
            sx={{
              color: "white",
              paddingBottom: "10px",
              justifyContent: "center",
              paddingTop: { xs: 8, sm: 12, md: 15 },
              fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
              fontWeight: "bold",
              letterSpacing: "3px",
              textTransform: "uppercase",
            }}
          >
            {pageTitle}
          </Typography>
        )}

        {children}

        {sections.map((section, index) => (
          <Box key={index} className="section" sx={{ py: { xs: 5, sm: 0 } }}>
            <Divider sx={{ backgroundColor: "white", height: ".25px" }} />
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
                sm: section.image
                  ? index % 2 === 0
                    ? "row"
                    : "row-reverse"
                  : "column",
              }}
              alignItems="center"
              sx={{
                position: "relative",
                padding: { xs: "3em 0", md: "0" },
              }}
            >
              <Box
                flex={1}
                padding="30px"
                sx={{
                  zIndex: 2,
                  position: "relative",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: "white", paddingBottom: "10px" }}
                >
                  {section.title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "white" }}
                >
                  {section.subheader}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "white", paddingTop: "10px" }}
                >
                  {section.content}
                </Typography>
              </Box>
              {section.image && (
                <Box
                  flex={1}
                  sx={{
                    zIndex: 1,
                    position: { xs: "absolute", sm: "static" },
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "block",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundImage:
                        "linear-gradient(to right, rgba(0,0,0,.3), rgba(0,0,0,0.9))",
                      display: { xs: "block", sm: "none" },
                    },
                  }}
                >
                  <LazyLoadImage
                    src={section.image}
                    alt="Section Image"
                    effect="blur"
                    onLoad={handleOnLoad}
                    style={imageStyle}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Footer />
    </Box>
  );
};

export default PageLayout;
