import React from 'react';
import DigitalExpansion from '../assets/images/DigitalExpansion.png';
import DesignSystems from '../assets/images/DesignSystems.png';
import ContentManagement from '../assets/images/ContentManagement.png';
import PageLayout from '../components/PageLayout';

const sections = [
  {
    title: 'REACT NATIVE APP DEVELOPMENT',
    subheader: "Build cross-platform mobile apps with efficiency and speed.",
    content: (
      <div>
        <p>
          Utilize React Native to create powerful mobile applications that run seamlessly on both iOS and Android. 
          Our expertise in React Native ensures fast development, consistent performance, and an excellent user experience 
          across all devices.
        </p>
      </div>
    ),
    image: DigitalExpansion,
  },
  {
    title: 'NATIVE APPS WITH SWIFT AND KOTLIN',
    subheader: "Crafting specialized apps for iOS and Android.",
    content: (
      <div>
        <p>
          For applications requiring native capabilities, we develop using Swift for iOS and Kotlin for Android. 
          This approach allows us to leverage the latest platform features, optimize performance, and ensure a high-quality, 
          native user experience.
        </p>
      </div>
    ),
    image: DesignSystems,
  },
  {
    title: 'ADVANCED FEATURES',
    subheader: "Incorporating barcode scanning, speech-to-text, and IoT integrations.",
    content: (
      <div>
        <p>
          Our mobile applications aren't just about great UI/UX - they're also packed with advanced features. 
          From efficient barcode scanning and intuitive speech-to-text functionality to smart IoT product integrations, 
          we ensure our apps meet the diverse needs of modern users and businesses.
        </p>
      </div>
    ),
    image: ContentManagement,
  },
];

const MobileAppDevelopmentPage = () => {
  return (
    <PageLayout pageTitle="Mobile App Development" sections={sections}>
      {/* Additional content if needed */}
    </PageLayout>
  );
};

export default MobileAppDevelopmentPage;
